/* Nav Bar */

.header {
  position: absolute;
  top: 0;
  width: 100%;
  background: transparent;

}

.headerContent {
  display: flex;
  /* justify-content: space-between; */
}

.navNameLink {
  font-size: 1.5rem;
  padding: 3rem 1rem 3rem 2rem;
  color: white;
  text-decoration: none;
}

.nav {
  position: fixed;
  background: #27211e;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%;
  transform: translateX(0);
  transition: transform 250ms;
}

.navigationOpen {
  transform: translateX(-100%);
}

.navList {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;

  align-items: flex-start;
  /* justify-content: space-around; */
  justify-content: center;

  flex-direction: column;

  text-align: left;
  padding: 0 6em;
}

.navLink {
  color: #fff;
  text-decoration: none;
  font-size: 1.9rem;
  font-weight: 700;
  display: block;
  padding: 0.2rem 0 0;

}

.navLink:hover,
.navLink:focus {
  color: #ffe600;
}

.closeNav {
  border: 0;
  background: 0;
  color: #ffe600;
  font-weight: 700;
  font-size: 3rem;
  cursor: pointer;
  padding: 0.25em 1em;
  position: absolute;
  top: 0;
  left: 0;
}

.openNav {
  border: 0;
  background: 0;
  color: #fff;
  cursor: pointer;
  padding: 2em;
  margin-left: auto;
  font-size: 1.5em;
}


@media (min-width: 850px) {
  .openNav,
  .closeNav {
    display: none;
  }

  .nav {
    position: initial;
    /* padding-right: 2em; */
    padding-right: 5rem;
  }

  .navNameLink {
    flex-shrink: 0;
    padding: 3rem 1rem 3rem 5rem;
    /* padding: 3rem 0; */
    /* margin: 0 auto; */

  }

  .navList {
    flex-direction: row;
    /* justify-content: flex-start; */
    justify-content: flex-end;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
  }

  .navItem {
    margin-left: 2em;
  }

  .navLink {
    font-size: 1rem;
    padding: 0;
  }

  .headerHome .nav {
    background: transparent;
  }


}

/* Nav Dropdown */

.dropdown {
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (min-width: 850px) {
  li {
    display: block;
  }

  .dropdownButton {
    /* display: flex; */
    position: relative;
    margin-left: 2rem;
  }

  .dropdown {
    visibility: hidden;
    display: none;
    opacity: 0;
    position: absolute;
    transition: all 0.5s ease;
    /* margin-top: 1rem; */
    /* display: flex;
      flex-direction: column; */

    padding: 0 0 20px 0;
    /* margin: 1rem 0 0; */
    /* left: 0; */
    /* top:0; */
    list-style: none;
    /* min-height: 50px; */
    text-align: left;
  }

  .dropdownButton:hover > ul,
  .dropdownButton ul:hover {
    visibility: visible;
    display: flex;
    display: block;
    opacity: 1;
    justify-content: flex-start;
  }

  .navLiHiddden,
  .navLinkHidden {
    margin: 0;
    padding: 0;
    /* float: left; */

    color: white;
  }

  .navLiHidden {
    width: 100%;
    text-decoration: none;
    list-style: none;
    display: block;
    padding: 1rem 0 0;
    white-space: nowrap;
  }
}
