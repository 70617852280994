/* ------------
    Layout
-------------- */

.mainGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

  grid-template-areas:
    "main"
    "secondary";

  margin: 20rem 0 0;
}

.homeVideo {
  width: 100vw;
  height: 20rem;
  object-fit: cover;

  margin: 0;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.homeMain,
.homeSecondary {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #27211e;
}

.homeMain {
  grid-area: main;
}

.homeTitle {
  font-size: 4.5rem;
  padding: 0;
}

.homeQuote {
  font-size: 1.2rem;
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}

@media (min-width: 850px) {
  .mainGrid {
    margin: 0;
    grid-template-rows: 40rem 40rem;
  }

  .homeMain {
    background-color: initial;
    margin-top: 0;
    padding: 5em 0;
    grid-area: main;
  }

  .homeTitle {
    padding: 0;
  }

  .homeVideo {
    height: 40rem;
  }

  .homeSecondary {
    width: 100%;
  }

  .homeQuote {
    max-width: max-content;
    width: 100%;
  }
}
