.grid {
  display: grid;
  grid-template-areas:
    "textContent"
    "headshot";
  margin: 4rem auto;
  max-width: 500px;
}

.content {
  grid-area: textContent;
  padding: 2em;
}

.title {
  text-align: left;
}

.buttonContainer {
  margin: 0 0 4em;
}

.btn {
  border: 1px solid #ffffff;
  padding: 6px 12px;
  text-decoration: none;
  transition: background-color 0.5s ease;
}

.btnPrimary {
  background-color: white;
  color: #27211e;
}

.btnPrimary:hover,
.btnPrimary:focus{
  background-color: transparent;
  color: white;
}

.btnClear {
  background-color: transparent;
  color: white;
  margin-right: 1rem;
  font-family: inherit;
  font-size: inherit;
}

.btnClear:hover,
.btnClear:focus {
  background-color: white;
  color: #27211e;
}



.headshot {
  grid-area: headshot;
  max-width: 100%;
  object-fit: cover;
  padding: 2em;
}

@media (min-width: 850px) {
  .grid {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "textContent headshot";
    margin: 9rem 0 0;
    max-width: none;
  }

  .content {
    padding: 0 5rem;
    margin: 0 auto;
    line-height: 1.7;
    max-width: 600px;
  }

  .title {
    margin-top: 0;
  }

  .headshot {
    padding-right: 5rem;
    margin: 0 auto;
  }
}
