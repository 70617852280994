.container {
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  align-items: center;
}

.textConent {
  width: 45%;
  text-align: center;
  margin: 0 auto 3rem;
}

.image {
  object-fit: cover;
  width: 90%;
  margin-bottom: 3rem;
}

.instaIcon {
  color: #fff;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 700;
  /* display: block; */
  /* padding: 0.2rem 0 0; */
  /* margin-left: 50%; */
  /* width: fit-content; */
  transition: color 0.5s ease;
}

.instaIcon:hover,
.instaIcon:focus {
  color: black;
}

@media (min-width: 850px) {
    .container {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 9rem;
    }

    .image {
        width: 45%;
        margin-right: 4rem;
    }
}
