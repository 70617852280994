.title {
  margin: 9rem auto 4rem;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.image,
.textContent {
  width: 75%;
  /* margin: 0 5rem; */
  padding: 0;
}

.image {
  object-fit: cover;
  object-position: top center;
  order: 2;
  max-height: 600px;
  padding-bottom: 3rem;
  border-bottom: 1px solid grey;
}

.card:last-of-type .image{
  border: none;
  padding-bottom: 0;
}

.textContent {
    order: 1;
  text-align: center;
}


@media (min-width: 850px) {
  .card {
    flex-direction: row;
  }

  .textContent {
    margin: 0 5rem 0 0;
  }

  .image,
  .textContent {
      order: initial;
    /* margin: 0 5rem; */
    padding: 0;
  }

  .image {
    width: 40%;
    /* max-width: 700px; */
    margin: 0 2rem 0 5rem;
    border: none;
  }

  .textContent {
    width: 50%;
  }
}

.noPhotoCred {
  display: none;
}