.title {
  margin: 9rem auto 2rem;
}

.cardContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "image"
    "text";
  place-items: center;
  margin-bottom: 4rem;
}

.cardContainer:last-of-type {
  margin-bottom: 2rem;
}

.image {
  grid-area: image;
  width: 100%;
}
.textContent {
  grid-area: text;
}

.textContent {
  text-align: center;
}

.credits {
  margin-bottom: 2rem;
}

.credits * {
  margin: 0.5rem;
}

.btn {
  padding: 6px 12px;
  border: 1px solid #ffffff;
  transition: background-color 0.5s ease;
  text-decoration: none;
}

.btnDark {
  background-color: white;
  color: #27211e;
}

.btnDark:hover,
.btnDark:focus {
  background-color: #27211e;
  color: white;
}

@media (min-width: 850px) {
  .title {
    margin: 9rem auto 4rem;
  }

  .cardContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "content";
    place-items: center;
  }

  .image,
  .textContent {
    grid-area: content;
  }

  .image {
    filter: brightness(80%);
    z-index: 0;
  }

  .textContent {
    z-index: 1;
  }

  .credits {
    margin-bottom: 1rem;
  }

  .btnDark {
    background-color: #27211e;
    color: white;
  }

  .btnDark:hover,
  .btnDark:focus {
    color: #27211e;
    background-color: white;
  }

  .loveInTheDarkText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
  .loveInTheDarkTitle,
  .loveInTheDarkBottomSection {
    padding: 10% 0;
  }
}
