.title {
    margin: 9rem auto 3rem;
    text-align: center;
    width: 100%;
}

.container {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(250px, 350px) );
    justify-content: center;
}

.card {
    padding: 2rem;
    margin: 0 auto;
}

.image{
    object-fit: cover;
    width: 100%;
}

.noImage {
    align-self: flex-end;
}

.noImage .image, .noPhotoCred{
    display: none;
}