.title {
    margin: 9rem auto 3rem;
    text-align: center;
    width: 100%;
}

.container {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(100px, 600px) );
    /* grid-auto-rows: 0.5fr; */
    grid-auto-columns: minmax(100px, 600px);
    grid-auto-rows: minmax(100px, 600px);
    justify-content: center;
    gap: 20px;
    margin: 0 2rem;
    /* overflow: hidden; */
}

.images{
    width: 100%;
    height: 100%;
    /* max-width: 100%;
   max-height: 100%; */
    /* display: block; */
    object-fit: cover;
    /* object-position: left; */
    
}

.credit {
    text-align: center;
    margin: 4rem 0 2rem;
}