.container {
    margin-top: 9rem;
  }

.item {
    padding: 1rem 2rem;
}

.itemYear {
    font-weight: 400;
}



@media (min-width: 850px) {
    .container{
        /* padding-left: 4rem; */
        margin: 9rem auto 0;
    }
    .item {
        /* padding-left: 2rem;  */
        margin-left: 10vw;
        width: fit-content;
    }
}
